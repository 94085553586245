<template>
  <div class="edit-handler-modal" @click="handleOverlayClick">
    <div class="modal-content">
      <h2>Редактирование Handler: {{ handlerName }}</h2>

      <!-- Keyfilter (переключатель) -->
      <div class="form-group">
        <label for="keyfilter">Фильтрация (ключевые слова и регулярные выражения)</label>
        <label class="switch">
          <input
            type="checkbox"
            v-model="formData.keyfilter"
            @change="handleKeyfilterChange"
          />
          <span class="slider round"></span>
        </label>
      </div>

      <!-- Формы, отображаемые только при включенном keyfilter -->
      <div v-if="formData.keyfilter">
        <!-- Гибкие ключевые слова -->
        <div class="form-group">
          <label for="keywords">Гибкие ключевые слова (Keywords)</label>
          <textarea
            id="keywords"
            v-model="formData.keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите гибкие ключевые слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Гибкие ключевые слова могут быть частями слов. Например, "купить" найдет "приКУПИТЬ...", "заКУПИТЬ..." и т.д.
          </small>
        </div>

        <!-- Точные ключевые слова -->
        <div class="form-group">
          <label for="exact_keywords">Точные ключевые слова (Exact Keywords)</label>
          <textarea
            id="exact_keywords"
            v-model="formData.exact_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите точные ключевые слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Точные ключевые слова должны точно совпадать с сообщением. Например, "привет мир" найдет только "привет мир".
          </small>
        </div>

        <!-- Гибкие слова-исключения -->
        <div class="form-group">
          <label for="excluded_keywords">Гибкие слова-исключения (Excluded Keywords)</label>
          <textarea
            id="excluded_keywords"
            v-model="formData.excluded_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите гибкие исключенные слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Гибкие исключенные слова исключат сообщения, содержащие эти слова или их части. Аналогично с гибкими ключевыми словами.
          </small>
        </div>

        <!-- Точные слова-исключения -->
        <div class="form-group">
          <label for="exact_excluded_keywords">Точные слова-исключения (Exact Excluded Keywords)</label>
          <textarea
            id="exact_excluded_keywords"
            v-model="formData.exact_excluded_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите точные исключенные слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Точные исключенные слова исключат сообщения, содержащие эти фразы в точном совпадении. Аналогично с точными ключевыми словами.
          </small>
        </div>

        <!-- Мультиусловия -->
        <div class="form-group">
          <label for="multi_keywords">
            Мультиусловия (Multi Keywords)
          </label>
          <textarea
            id="multi_keywords"
            v-model="formData.multi_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Например: +ключевое слово++точная фраза-исключение--точное исключение"
          ></textarea>
          <small class="form-text text-muted">
            Используйте '+' для гибких ключевых слов, '++' для точных ключевых фраз, '-' для гибких исключений и '--' для точных исключений. Например: <code>+привет++добрый день-спам--точное исключение</code>
          </small>
        </div>

        <!-- Регулярные выражения -->
        <div class="form-group">
          <label>Регулярные выражения (Regex Patterns)</label>
          <div
            v-for="(regex, index) in formData.regex_patterns"
            :key="index"
            class="regex-pattern"
          >
            <h4>Регулярное выражение {{ index + 1 }}</h4>
            <div class="nested-form-group">
              <label :for="`regex_pattern_${index}`">Pattern</label>
              <input
                type="text"
                :id="`regex_pattern_${index}`"
                v-model="regex.pattern"
                placeholder="Введите регулярное выражение"
              />
            </div>

            <div class="nested-form-group">
              <label :for="`regex_flags_${index}`">Flags</label>
              <input
                type="text"
                :id="`regex_flags_${index}`"
                v-model="regex.flags"
                placeholder="Введите флаги (например, I для re.IGNORECASE)"
              />
            </div>

            <div class="nested-form-group">
              <label :for="`regex_excluded_keywords_${index}`">Local Excluded Keywords</label>
              <textarea
                :id="`regex_excluded_keywords_${index}`"
                v-model="regex.excluded_keywords"
                class="scrollable-textarea"
                rows="3"
                placeholder="Введите гибкие исключенные слова, каждое с новой строки"
              ></textarea>
            </div>

            <div class="nested-form-group">
              <label :for="`regex_exact_excluded_keywords_${index}`">Local Exact Excluded Keywords</label>
              <textarea
                :id="`regex_exact_excluded_keywords_${index}`"
                v-model="regex.exact_excluded_keywords"
                class="scrollable-textarea"
                rows="3"
                placeholder="Введите точные исключенные слова, каждое с новой строки"
              ></textarea>
            </div>

            <div class="nested-form-group">
              <label :for="`regex_hashtag_${index}`">Local Hashtag</label>
              <input
                type="text"
                :id="`regex_hashtag_${index}`"
                v-model="regex.hashtag"
                placeholder="Введите локальный хэштег (опционально)"
              />
            </div>

            <!-- Новые поля для локальных настроек -->
            <div class="nested-form-group">
              <label>Local GPT</label>
              <label class="switch">
                <input
                  type="checkbox"
                  v-model="regex.gpt"
                />
                <span class="slider round"></span>
              </label>
            </div>

            <div class="nested-form-group" v-if="isAdmin">
              <label :for="`regex_gpt_version_${index}`">Local GPT Version</label>
              <input
                type="text"
                :id="`regex_gpt_version_${index}`"
                v-model="regex.gpt_version"
                placeholder="Введите версию GPT"
              />
            </div>

            <div class="nested-form-group" v-if="isAdmin">
              <label :for="`regex_current_prompt_${index}`">Local Current Prompt</label>
              <textarea
                :id="`regex_current_prompt_${index}`"
                v-model="regex.current_prompt"
                class="scrollable-textarea"
                rows="12"
              ></textarea>
            </div>

            <div class="nested-form-group" v-if="isAdmin">
              <label :for="`regex_group_id_gpt_no_${index}`">Local Group IDs GPT No</label>
              <textarea
                :id="`regex_group_id_gpt_no_${index}`"
                v-model="regex.group_id_gpt_no"
                class="scrollable-textarea"
                rows="3"
                placeholder="Введите ID групп GPT No, каждое с новой строки"
              ></textarea>
            </div>

            <div class="nested-form-group" v-if="isAdmin">
              <label :for="`regex_group_id_no_nickname_${index}`">Local Group IDs YES-</label>
              <textarea
                :id="`regex_group_id_no_nickname_${index}`"
                v-model="regex.group_id_no_nickname"
                class="scrollable-textarea"
                rows="3"
                placeholder="Введите ID групп без никнейма, каждое с новой строки"
              ></textarea>
            </div>

            <div class="nested-form-group" v-if="isAdmin">
              <label :for="`regex_group_id_with_nickname_${index}`">Local Group IDs YES+</label>
              <textarea
                :id="`regex_group_id_with_nickname_${index}`"
                v-model="regex.group_id_with_nickname"
                class="scrollable-textarea"
                rows="3"
                placeholder="Введите ID групп с никнеймом, каждое с новой строки"
              ></textarea>
            </div>

            <div class="nested-form-group" v-if="isAdmin">
              <label :for="`regex_black_list_${index}`">Local Black List</label>
              <textarea
                :id="`regex_black_list_${index}`"
                v-model="regex.black_list"
                class="scrollable-textarea"
                rows="3"
                placeholder="Введите ID пользователей для черного списка, каждое с новой строки"
              ></textarea>
            </div>

            <div class="nested-form-group" v-if="isAdmin">
              <label :for="`regex_monitor_users_${index}`">Local Monitor Users</label>
              <textarea
                :id="`regex_monitor_users_${index}`"
                v-model="regex.monitor_users"
                class="scrollable-textarea"
                rows="3"
                placeholder="Введите ID пользователей для мониторинга, каждое с новой строки"
              ></textarea>
            </div>

            <!-- Новый текстовый блок для Local Formatted Message -->
            <div class="nested-form-group">
              <label :for="`regex_formated_message_${index}`">Local Formatted Message</label>
              <textarea
                :id="`regex_formated_message_${index}`"
                v-model="regex.formated_message"
                class="scrollable-textarea"
                rows="6"
                placeholder="Введите строки форматированного сообщения, каждая строка — отдельный элемент массива"
              ></textarea>
            </div>

            <button class="secondary-button" @click="removeRegexPattern(index)">Удалить Регулярное Выражение</button>
            <hr />
          </div>
          <button class="primary-button" @click="addRegexPattern">Добавить Регулярное Выражение</button>
        </div>
      </div>

      <!-- Новое поле для глобального Formatted Message -->
      <div class="form-group">
        <label for="global_formated_message">Global Formatted Message</label>
        <textarea
          id="global_formated_message"
          v-model="formData.formated_message"
          class="scrollable-textarea"
          rows="6"
          placeholder="Введите строки форматированного сообщения, каждая строка — отдельный элемент массива"
        ></textarea>
      </div>

      <!-- GPT -->
      <div class="form-group" v-if="isAdmin">
        <label for="gpt">Global GPT</label>
        <label class="switch">
          <input
            type="checkbox"
            v-model="formData.gpt"
            @change="handleGptChange"
          />
          <span class="slider round"></span>
        </label>
      </div>

      <!-- GPT Version -->
      <div class="form-group" v-if="isAdmin">
        <label for="gpt_version">Global GPT Version</label>
        <input
          type="text"
          id="gpt_version"
          v-model="formData.gpt_version"
          placeholder="Введите версию GPT"
        />
      </div>

      <!-- Current Prompt -->
      <div class="form-group" v-if="isAdmin">
        <label for="current_prompt">Global Current Prompt</label>
        <textarea
          id="current_prompt"
          v-model="formData.current_prompt"
          class="scrollable-textarea"
          rows="12"
        ></textarea>
      </div>

      <!-- Hashtag (поле отображается только если isAdmin) -->
      <div class="form-group" v-if="isAdmin">
        <label for="hashtag">Global Hashtag</label>
        <input
          type="text"
          id="hashtag"
          v-model="formData.hashtag"
          placeholder="Введите хэштег услуги"
        />
      </div>

      <!-- Group IDs -->
      <div class="form-group">
        <label for="group_ids">Global Group IDs</label>
        <textarea
          id="group_ids"
          v-model="formData.group_ids"
          class="scrollable-textarea"
          rows="6"
          placeholder="Введите ID групп, каждое с новой строки"
        ></textarea>
      </div>

      <!-- Monitor Users -->
      <div class="form-group">
        <label for="monitor_users">Global Monitor Users</label>
        <textarea
          id="monitor_users"
          v-model="formData.monitor_users"
          class="scrollable-textarea"
          rows="6"
          placeholder="Введите ID пользователей, каждое с новой строки"
        ></textarea>
      </div>

      <!-- Black List -->
      <div class="form-group">
        <label for="black_list">Global Black List</label>
        <textarea
          id="black_list"
          v-model="formData.black_list"
          class="scrollable-textarea"
          rows="6"
          placeholder="Введите ID пользователей для черного списка, каждое с новой строки"
        ></textarea>
      </div>

      <!-- Additional fields for Admin -->
      <div v-if="isAdmin">

        <!-- Group ID fields for Admin -->
        <div class="form-group">
          <label for="group_id_gpt_no">Global Group ID No</label>
          <textarea
            id="group_id_gpt_no"
            v-model="formData.group_id_gpt_no"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите ID групп GPT No, каждое с новой строки"
          ></textarea>
        </div>

        <div class="form-group">
          <label for="group_id_no_nickname">Global Group ID YES-</label>
          <textarea
            id="group_id_no_nickname"
            v-model="formData.group_id_no_nickname"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите ID групп без никнейма, каждое с новой строки"
          ></textarea>
        </div>

        <div class="form-group">
          <label for="group_id_with_nickname">Global Group ID YES+</label>
          <textarea
            id="group_id_with_nickname"
            v-model="formData.group_id_with_nickname"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите ID групп с никнеймом, каждое с новой строки"
          ></textarea>
        </div>
      </div>

      <div class="button-group">
        <button class="primary-button" @click="saveHandlerConfig">
          Сохранить
        </button>
        <button class="secondary-button" @click="closeModal">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/apiClient";

export default {
  props: {
    handlerName: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      handlerConfig: {},
      formData: {
        formated_message: "", // Добавлено для глобального formated_message
        current_prompt: "",
        keywords: "",
        exact_keywords: "",
        excluded_keywords: "",
        exact_excluded_keywords: "",
        multi_keywords: "",
        regex_patterns: [], // Массив объектов
        group_ids: "",
        keyfilter: false,
        monitor_users: "",
        gpt: false,
        gpt_version: "gpt-4o-mini",
        group_id_gpt_no: "",
        group_id_no_nickname: "",
        group_id_with_nickname: "",
        black_list: "",
        hashtag: "",
      },
      isLoading: false, // Для отображения состояния загрузки
    };
  },
  mounted() {
    document.body.style.overflow = "hidden"; // Отключаем прокрутку заднего экрана
    this.loadHandlerConfig();
  },
  beforeUnmount() {
    document.body.style.overflow = ""; // Включаем прокрутку заднего экрана при закрытии
  },
  methods: {
    async loadHandlerConfig() {
      this.isLoading = true;
      try {
        const response = await apiClient.get(
          `/mongo/handlers/${this.handlerName}`
        );
        this.handlerConfig = response.data;

        // Заполняем форму данными из конфигурации
        this.formData.current_prompt = (this.handlerConfig.current_prompt || []).join("\n");
        this.formData.keywords = (this.handlerConfig.keywords || []).join("\n");
        this.formData.exact_keywords = (this.handlerConfig.exact_keywords || []).join("\n");
        this.formData.excluded_keywords = (this.handlerConfig.excluded_keywords || []).join("\n");
        this.formData.exact_excluded_keywords = (this.handlerConfig.exact_excluded_keywords || []).join("\n");
        this.formData.multi_keywords = (this.handlerConfig.multi_keywords || []).join("\n");

        // Обработка regex_patterns как массива объектов
        this.formData.regex_patterns = (this.handlerConfig.regex_patterns || []).map((regex) => ({
          pattern: regex.pattern || "",
          flags: regex.flags || "",
          excluded_keywords: (regex.excluded_keywords || []).join("\n"),
          exact_excluded_keywords: (regex.exact_excluded_keywords || []).join("\n"),
          hashtag: regex.hashtag || "",
          gpt: regex.gpt || false,
          gpt_version: regex.gpt_version || "gpt-4o-mini",
          current_prompt: (regex.current_prompt || []).join("\n"),
          group_id_with_nickname: (regex.group_id_with_nickname || []).join("\n"),
          group_id_no_nickname: (regex.group_id_no_nickname || []).join("\n"),
          group_id_gpt_no: (regex.group_id_gpt_no || []).join("\n"),
          black_list: (regex.black_list || []).join("\n"),
          monitor_users: (regex.monitor_users || []).join("\n"),
          formated_message: (regex.formated_message || []).join("\n"), // Добавлено для локального formated_message
        }));

        this.formData.group_ids = (this.handlerConfig.group_ids || []).join("\n");
        this.formData.keyfilter = this.handlerConfig.keyfilter || false;
        this.formData.monitor_users = (this.handlerConfig.monitor_users || []).join("\n");
        this.formData.gpt = this.handlerConfig.gpt || false;
        this.formData.gpt_version = this.handlerConfig.gpt_version || "gpt-4o-mini";
        this.formData.hashtag = this.handlerConfig.hashtag || "";

        // Преобразуем массивы чисел в строки для корректного отображения
        this.formData.group_id_gpt_no = (this.handlerConfig.group_id_gpt_no || []).join("\n");
        this.formData.group_id_no_nickname = (this.handlerConfig.group_id_no_nickname || []).join("\n");
        this.formData.group_id_with_nickname = (this.handlerConfig.group_id_with_nickname || []).join("\n");
        this.formData.black_list = (this.handlerConfig.black_list || []).join("\n");

        // Заполнение глобального formated_message
        this.formData.formated_message = (this.handlerConfig.formated_message || []).join("\n");
      } catch (error) {
        console.error("Ошибка при загрузке конфигурации хэндлера:", error);
        alert("Не удалось загрузить конфигурацию хэндлера. Проверьте консоль для подробностей.");
      } finally {
        this.isLoading = false;
      }
    },
    async saveHandlerConfig() {
      this.isLoading = true;
      try {
        // Подготовка regex_patterns как массива объектов
        const preparedRegexPatterns = this.formData.regex_patterns.map((regex) => ({
          pattern: regex.pattern.trim(),
          flags: regex.flags.trim(),
          excluded_keywords: regex.excluded_keywords
            .split("\n")
            .map((kw) => kw.trim())
            .filter(Boolean),
          exact_excluded_keywords: regex.exact_excluded_keywords
            .split("\n")
            .map((kw) => kw.trim())
            .filter(Boolean),
          hashtag: regex.hashtag.trim(),
          gpt: regex.gpt,
          gpt_version: regex.gpt_version.trim() || "gpt-4o-mini",
          current_prompt: regex.current_prompt.split("\n"),
          group_id_with_nickname: regex.group_id_with_nickname
            ? regex.group_id_with_nickname
                .split("\n")
                .map((id) => id.trim())
                .filter(Boolean)
            : [],
          group_id_no_nickname: regex.group_id_no_nickname
            ? regex.group_id_no_nickname
                .split("\n")
                .map((id) => id.trim())
                .filter(Boolean)
            : [],
          group_id_gpt_no: regex.group_id_gpt_no
            ? regex.group_id_gpt_no
                .split("\n")
                .map((id) => id.trim())
                .filter(Boolean)
            : [],
          black_list: regex.black_list
            ? regex.black_list
                .split("\n")
                .map((id) => id.trim())
                .filter(Boolean)
            : [],
          monitor_users: regex.monitor_users
            ? regex.monitor_users
                .split("\n")
                .map((id) => id.trim())
                .filter(Boolean)
            : [],
          formated_message: regex.formated_message
            ? regex.formated_message
                .split("\n")
                .map((line) => line.trim())
            : [],
        }));

        const updateData = {
          formated_message: this.formData.formated_message
            .split("\n")
            .map((line) => line.trim()),
          current_prompt: this.formData.current_prompt
            .split("\n"),
          keywords: this.formData.keywords
            .split("\n")
            .map((kw) => kw.trim())
            .filter(Boolean),
          exact_keywords: this.formData.exact_keywords
            .split("\n")
            .map((kw) => kw.trim())
            .filter(Boolean),
          excluded_keywords: this.formData.excluded_keywords
            .split("\n")
            .map((kw) => kw.trim())
            .filter(Boolean),
          exact_excluded_keywords: this.formData.exact_excluded_keywords
            .split("\n")
            .map((kw) => kw.trim())
            .filter(Boolean),
          multi_keywords: this.formData.multi_keywords
            .split("\n")
            .map((kw) => kw.trim())
            .filter(Boolean),
          regex_patterns: preparedRegexPatterns,
          group_ids: this.formData.group_ids
            .split("\n")
            .map((id) => id.trim())
            .filter(Boolean)
            .map((id) => parseInt(id, 10))
            .filter((id) => !isNaN(id)),
          keyfilter: this.formData.keyfilter,
          monitor_users: this.formData.monitor_users
            .split("\n")
            .map((id) => id.trim())
            .filter(Boolean),
          gpt: this.formData.gpt,
          gpt_version: this.formData.gpt_version.trim() || "gpt-4o-mini",
          group_id_gpt_no: this.formData.group_id_gpt_no
            .split("\n")
            .map((id) => id.trim())
            .filter(Boolean),
          group_id_no_nickname: this.formData.group_id_no_nickname
            .split("\n")
            .map((id) => id.trim())
            .filter(Boolean),
          group_id_with_nickname: this.formData.group_id_with_nickname
            .split("\n")
            .map((id) => id.trim())
            .filter(Boolean),
          black_list: this.formData.black_list
            .split("\n")
            .map((id) => id.trim())
            .filter(Boolean),
          hashtag: this.formData.hashtag.trim(),
        };

        console.log("Update Data:", updateData); // Логирование для проверки данных

        const response = await apiClient.put(`/mongo/handlers/${this.handlerName}`, updateData);
        console.log("Успешно сохранено:", response.data);
        this.closeModal();
      } catch (error) {
        console.error("Ошибка при сохранении конфигурации хэндлера:", error);
        if (error.response) {
          console.error("Детали ошибки:", error.response.data);
          alert(`Ошибка: ${JSON.stringify(error.response.data)}`);
        } else {
          alert("Произошла неизвестная ошибка.");
        }
      } finally {
        this.isLoading = false;
      }
    },
    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        if (confirm("Вы уверены, что хотите закрыть окно?")) {
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    addRegexPattern() {
      this.formData.regex_patterns.push({
        pattern: "",
        flags: "",
        excluded_keywords: "",
        exact_excluded_keywords: "",
        hashtag: "",
        gpt: false,
        gpt_version: "gpt-4o-mini",
        current_prompt: "",
        group_id_with_nickname: "",
        group_id_no_nickname: "",
        group_id_gpt_no: "",
        black_list: "",
        monitor_users: "",
        formated_message: "", // Добавлено для локального formated_message
      });
    },
    removeRegexPattern(index) {
      if (confirm("Вы уверены, что хотите удалить это регулярное выражение?")) {
        this.formData.regex_patterns.splice(index, 1);
      }
    },
    handleKeyfilterChange() {
      // Дополнительная логика при изменении keyfilter, если необходимо
    },
    handleGptChange() {
      // Дополнительная логика при изменении GPT, если необходимо
    },
  },
};
</script>



<style scoped>
.edit-handler-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden; /* Отключаем прокрутку заднего экрана */
}

.modal-content {
  background: #fff;
  padding: 30px; /* Увеличенное расстояние внутри */
  border-radius: 12px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh; /* Ограничиваем высоту модального окна */
  overflow-y: auto; /* Включаем прокрутку, если контент превышает высоту окна */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Глубокая тень для объема */
  animation: fadeIn 0.3s ease-in-out; /* Анимация появления */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.form-group small {
  display: block;
  margin-top: 5px;
  color: #6c757d;
  font-size: 0.875em;
}

.form-group code {
  background-color: #f8f8f8;
  padding: 2px 4px;
  font-size: 0.875em;
  border-radius: 4px;
  color: #e83e8c;
}

.scrollable-textarea {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  resize: none; /* Запрещаем изменение размера */
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: 1rem;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
}

.regex-pattern {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
}

.nested-form-group {
  margin-bottom: 10px;
}

.regex-pattern h4 {
  margin-bottom: 10px;
  color: #007bff;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.primary-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.primary-button:hover {
  background-color: #0056b3;
}

.secondary-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.secondary-button:hover {
  background-color: #5a6268;
}
</style>